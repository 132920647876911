import React, { useEffect, useState } from "react";
import Lock from "./static/unlock.svg";
import Google from "./static/google.svg";
import { LoginForm } from "./components";
import { Link, redirect, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { axios } from "./config/axios";
import "./App.css";
import "@snapatoms/ui/dist/bundle.css";
import { Http } from "./utils/http.constants";
import { Button } from "./components/button/Button";
import { AxiosError, AxiosResponse } from "axios";

import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Alert, AlertTitle } from "@mui/material";
import { ConfigContextObject } from "./config/use-config";
import { defaultConfig, DynamicConfig } from "./config/config";

interface LoginPayload {
  refer?: string;
  callback?: string;
  expiry?: string;
  url: string;
  auth: string;
  token: string;
  sso: string;
}

interface SSORedirectResponse {
  token: string;
  redirect: string;
  sso: string;
  auth: string;
}

function App() {
  const [configState, setConfigState] = useState(defaultConfig);
  const [capturedRedirect, setRedirectUrl] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [username, setUsername] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const hasRedirect = searchParams.get("redirect") as string;
    setRedirectUrl(hasRedirect);
    setSearchParams("");
  }, []);

  useEffect(() => {
    axios.get("/.config").then((resp: AxiosResponse) => {
      setConfigState(resp.data);
    });
  }, []);

  const reroute = ({ token, auth, sso }: SSORedirectResponse) => {
    const redirect = `${capturedRedirect}?token=${token}&auth=${auth}&sso=http://${sso}`;
    window.location.href = redirect as string;
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      axios
        .post(`/oauth/google/token`, tokenResponse)
        .then((res: AxiosResponse) => {
          console.log(tokenResponse);
          if (capturedRedirect) {
            axios.post("/oauth/sso", { redirect: capturedRedirect }).then((res: AxiosResponse<SSORedirectResponse>) => {
              reroute(res.data);
            });
          } else {
            axios.get("/self").then((res: AxiosResponse) => {
              console.log(res);
              return navigate("/app");
            });
          }
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 403) {
            alert("Account not registered");
          }
        });
    },
    onError: (errorResponse) => {
      console.log(errorResponse);
    },
    flow: "auth-code",
  });

  async function handler(username: string, password: string, direct: boolean = false) {
    if (capturedRedirect && !direct) {
      axios
        .post("/sso", { username, password, redirect: capturedRedirect })
        .then((res: AxiosResponse<LoginPayload>) => {
          if (res.status == 200) {
            const redirect = `${capturedRedirect}?token=${res.data.token}&auth=${res.data.auth}&sso=http://${res.data.sso}`;
            window.location.href = redirect as string;
          }
        })
        .catch((err: AxiosError) => {});
      return;
    }

    axios.post("/", { username, password }).then((res: AxiosResponse<LoginPayload>) => {
      return navigate("/app");
    });
  }

  function checkQualifiedEmail(): boolean {
    return /@*\./.test(username);
  }

  return (
    <ConfigContextObject.Provider
      value={{
        config: configState,
        setConfig: setConfigState,
      }}
    >
      <div className="App">
        <header className="App-header">
          {/* <Alert severity="error">Ooops, looks like your details are invalid</Alert> */}
          <div className="form-container form-theme-dark">
            <div className="row">
              <div className="col">
                <img className="icon-sm" src={Lock} alt="Your SVG" />
              </div>
            </div>
            <LoginForm
              type="submit"
              handler={handler}
              setUsername={setUsername}
              showAuthLogin={capturedRedirect}
            ></LoginForm>
            <Link to="/reset" state={username} hidden={!checkQualifiedEmail()}>
              Forgot Password
            </Link>
          </div>
          <img src={Google} className="icon-sm" onClick={login}></img>
          {/* <GoogleLogin onSuccess={(credentials) => console.log(credentials)}></GoogleLogin> */}
        </header>
      </div>
    </ConfigContextObject.Provider>
  );
}

export default App;
