export interface DynamicConfig {
  api: string;
  name: string;
  environment: "DEV" | "TEST" | "STAGING" | "PROD";
}

export const defaultConfig: DynamicConfig = {
  api: "https://auth.charleybyrne.com",
  name: "Auth",
  environment: "DEV",
};

export const dynamicConfigUrl = "config.json";
