import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Header } from "./header/Header";

export function Layout() {
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
}
