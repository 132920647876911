import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { createBrowserRouter, redirect, RouterProvider, useSearchParams } from "react-router-dom";
import App from "./App";

import { axios } from "./config/axios";
import "./index.scss";

import reportWebVitals from "./reportWebVitals";
import { guard } from "./auth/loader";
import { Layout } from "./components/Layout";
import { Settings } from "./pages/Settings";
import { Public } from "./pages/Public";
import { Users } from "./pages/Users";
import { SSO } from "./pages/SSO";
import { Reset } from "./pages/Reset";
import { Profile } from "./pages/Profile";

let routes = createBrowserRouter([
  {
    path: "/",
    loader() {
      return {};
    },
    Component: App,
  },
  {
    path: "/app",
    loader: guard,
    Component: Layout,
    children: [
      {
        index: true,
        Component: Settings,
        // loader: guard,
      },
      {
        path: "profile",
        Component: Profile,
      },
      {
        path: "public",
        Component: Public,
        // loader: guard,
      },
      {
        path: "users",
        Component: Users,
        // loader: guard,
      },
    ],
  },
  {
    path: "/sso",
    Component: SSO,
  },
  {
    path: "/reset",
    Component: Reset,
  },
  {
    path: "/logout",

    loader: async (request: any) => {
      guard(request);
      await axios.get("/v1/logout");
      return redirect("/");
    },
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="613537349536-j04k3u5tgaci6pl52i0fk102ai216ane.apps.googleusercontent.com">
      <RouterProvider router={routes}></RouterProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
