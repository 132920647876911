import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import House from "../../static/house.svg";
import Users from "../../static/users.svg";

import "./Header.scss";

export function Header() {
  return (
    <header className="atoms-header">
      <ul>
        <li>
          <h3>Auth</h3>
        </li>
      </ul>
      <ul>
        <li>
          <Link to="/app">
            <div>
              <span>Home</span>
              <img className="icon-xs" src={House}></img>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/app/users">
            <div>
              <span>Users</span>
              <img className="icon-xs" src={Users}></img>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/app/public">Public Key</Link>
        </li>
        <li>
          <Link to="/logout">Logout</Link>
        </li>
      </ul>
    </header>
  );
}
