import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { redirect, useNavigate } from "react-router-dom";

const instance = axios.create();
instance.defaults.baseURL = "https://api.auth.charleybyrne.com";

instance.interceptors.request.use(
  (config) => {
    config.headers["X-Domain"] = "localhost";
    config.headers.Authorization = "Bearer token:common";
    config.withCredentials = true;
    return config;
  },
  (err: Error | AxiosError) => {
    if (axios.isAxiosError(err)) {
      if ([403, 301].includes(err.response?.status || 0)) {
        redirect("/");
      }
    }
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err: Error | AxiosError) => {
    if (axios.isAxiosError(err)) {
      if ([403, 401].includes(err.response?.status || 0)) {
        redirect("/");
      }
    }
    throw err;
  }
);

export { instance as axios };
