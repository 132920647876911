import React, { useContext, useState } from "react";
import { defaultConfig, DynamicConfig } from "./config";
import { axios } from "./axios";
import { AxiosResponse } from "axios";

interface DynamicConfigContext {
  config: DynamicConfig;
  setConfig: (newConfig: DynamicConfig) => void;
}

export const ConfigContextObject = React.createContext<DynamicConfigContext>({
  config: defaultConfig,
  setConfig: () => {},
});

export const useConfig = () => useContext(ConfigContextObject);
