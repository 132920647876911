import React, { useEffect, useState } from "react";
import { Card } from "../components/card/Card";
import { Button } from "../components/button/Button";
import { axios } from "../config/axios";
import { AxiosResponse } from "axios";

import "../components/table/Table.scss";

interface Domain {
  id: string;
  uuid: string;
  name: string;
}

export function Users() {
  const [domain, setDomain] = useState<string>();
  const [domains, setDomains] = useState<Domain[]>([]);

  useEffect(() => {
    axios.get("/v1/domain").then((res: AxiosResponse<Domain[]>) => {
      setDomains(res.data);
    });
  }, []);

  function deleteDomain(domain: Domain) {
    axios.delete(`/v1/domain/${domain.id}`).then((res: AxiosResponse) => {
      setDomains(domains.filter((d: Domain) => d.id !== domain.id));
    });
  }

  function handler(name: any) {
    axios
      .post("/v1/domain", { name: domain })
      .then((res: AxiosResponse<Domain>) => {
        setDomains([...domains, ...[res.data]]);
      })
      .catch((err: any) => {});
  }
  return (
    <div className="container">
      <h3>Authorization Settings</h3>
      <Button
        onClick={async () => {
          await axios
            .get("http://api.local.charleybyrne.com:8080/v1/health", {
              headers: { "X-Domain": "localhost", Host: "test.test.com" },
            })
            .catch((err: any) => {});
          await axios
            .get("http://local-site-a.charleybyrne.com:9000/v1/health", {
              headers: { "X-Domain": "localhost", Host: "test.test.com" },
            })
            .catch((err: any) => {});
        }}
      >
        Test
      </Button>

      <div className="row">
        <div className="col">
          <Card name="Available Domains">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UUID</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {domains.map((domain: Domain) => {
                  return (
                    <tr key={domain.id}>
                      <td>{domain.id}</td>
                      <td>{domain.uuid}</td>
                      <td>{domain.name}</td>
                      <td>
                        <Button onClick={() => deleteDomain(domain)} type="button" size="xs">
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Card name="Domains">
            <form name="login" onSubmit={(e) => e.preventDefault()}>
              <div className="row align-center">
                <div className="col-9">
                  <div className="form-group">
                    <input
                      className="form-field"
                      type="text"
                      autoComplete="new-password"
                      placeholder="Enter trusted domain"
                      onChange={(e) => setDomain(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <Button type="submit" size="sm" onClick={handler}>
                      Add Domain
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
}
