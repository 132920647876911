import React, { useEffect, useState } from "react";
import { Card } from "../components/card/Card";
import { Button } from "../components/button/Button";
import { axios } from "../config/axios";
import { AxiosResponse } from "axios";

import "../components/table/Table.scss";

interface Domain {
  id: string;
  uuid: string;
  name: string;
}

export function Settings() {
  const [domain, setDomain] = useState<string>();
  const [domains, setDomains] = useState<Domain[]>([]);

  const checkButtonText = () => {
    if (domain && domain.split(",").length > 1) {
      return "Add Domains";
    }
    return "Add Domain";
  };

  useEffect(() => {
    axios.get("/v1/domain").then((res: AxiosResponse<Domain[]>) => {
      setDomains(res.data);
    });
  }, []);

  function refreshDomain(domain: Domain) {
    console.log(domain);
    const handle = window.open(`http://${domain.name}`, "target", "_blank") as Window;
    handle.blur();
    // window.focus();
    setTimeout(() => {
      handle.close();
    }, 500);
  }

  function deleteDomain(domain: Domain) {
    axios.delete(`/v1/domain/${domain.id}`).then((res: AxiosResponse) => {
      setDomains(domains.filter((d: Domain) => d.id !== domain.id));
    });
  }

  function handler(name: any) {
    axios
      .post("/v1/domain", { name: domain })
      .then((res: AxiosResponse<Domain>) => {
        setDomains([...domains, ...[res.data]]);
        setDomain("");
      })
      .catch((err: any) => {});
  }
  return (
    <div className="container">
      <h3>Authorization Settings</h3>

      <div className="row">
        <div className="col">
          <Card name="Available Domains">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UUID</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {domains.map((domain: Domain) => {
                  return (
                    <tr key={domain.id}>
                      <td>{domain.id}</td>
                      <td>{domain.uuid}</td>
                      <td>
                        <a href={`http://${domain.name}`} target="_blank">
                          {domain.name}{" "}
                        </a>
                      </td>
                      <td>
                        <Button onClick={() => deleteDomain(domain)} type="button" size="xs">
                          <span>Delete</span>
                        </Button>
                        <Button onClick={() => refreshDomain(domain)} type="button" size="xs">
                          <span>Refresh</span>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Card name="Domains">
            <form name="domain" onSubmit={(e) => e.preventDefault()}>
              <p>Comma separate domains for multiple entries</p>
              <div className="row align-center">
                <div className="col-9">
                  <div className="form-group">
                    <input
                      className="form-field"
                      type="text"
                      autoComplete="new-password"
                      placeholder="Enter trusted domain"
                      onChange={(e) => setDomain(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <Button type="submit" size="sm" onClick={handler}>
                      {checkButtonText()}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
}
