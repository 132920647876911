import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";

import "./Card.scss";

export function Card(props: any) {
  return (
    <div className="atoms-card">
      <div className="atoms-card__header">
        <h4>{props.name}</h4>
      </div>
      <div className="atoms-card__body">{props.children}</div>
    </div>
  );
}
