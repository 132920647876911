import React, { useEffect, useState } from "react";
import { Card } from "../components/card/Card";
import { Button } from "../components/button/Button";
import { axios } from "../config/axios";
import { AxiosResponse } from "axios";

import "../components/table/Table.scss";

interface Public {
  key: string;
}

export function Public() {
  const [publicKey, setPublicKey] = useState<string>("");
  useEffect(() => {
    axios.get("/v1/public").then((res: AxiosResponse<Public>) => {
      setPublicKey(res.data.key);
    });
  }, []);

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  return (
    <div className="container">
      <Button type="button" onClick={async () => await copyTextToClipboard(publicKey)}>
        Copy Key
      </Button>
      <div className="row">
        <div className="col-6">
          <pre className="key-text">
            <code>{publicKey}</code>
          </pre>
        </div>
      </div>
    </div>
  );
}
