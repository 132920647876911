import { useEffect } from "react";
import { axios } from "../config/axios";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../components/button/Button";
import Plane from "../static/paper-plane.svg";

export function Reset() {
  const { state } = useLocation();

  async function trigger() {
    await axios.post("/v1/reset", { username: state });
  }

  useEffect(() => {
    trigger();
  }, []);
  return (
    <main className="App-header">
      <div className="container text-center">
        <img src={Plane} className="icon-md"></img>
        <div className="row">
          <div className="col">
            <p>If you possess a valid account, a password reset will now be sent to your registered email.</p>
          </div>
        </div>
        <Link to="/">
          <Button onClick={() => {}}>Return to Login</Button>
        </Link>
      </div>
    </main>
  );
}
