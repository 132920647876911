import React, { useEffect, useState } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";

export function SSO() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // redirect(searchParams.get("url") as string);
  });
  return <></>;
}
