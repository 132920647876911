import { MouseEventHandler, ReactHTMLElement } from "react";
import "./Button.scss";

export interface ButtonProps {
  label?: string;
  type?: "button" | "submit" | "reset";
  size?: "xs" | "sm" | "md" | "lg";
  onClick: CallableFunction;
  children: React.ReactNode;
}

export function Button(props: ButtonProps) {
  const handler: MouseEventHandler<HTMLButtonElement> = (event) => {
    // Your event handling logic here

    props.onClick();
  };

  return (
    <button className={`atoms-button atoms-button--${props.size}`} type={props.type || "button"} onClick={handler}>
      {props.children}
    </button>
  );
}
