import "./Form.scss";

import React, { useRef, useState } from "react";
import { Button } from "./button/Button";
import { useSearchParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export function LoginForm(props: any) {
  const [params, setSearchParams] = useSearchParams();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const form = useRef(null);

  function handler(direct = false) {
    props.handler(username, password, direct);
  }

  return (
    <div>
      <div className="row">
        <div className="col">
          <form
            name="login"
            onSubmit={(e) => {
              e.preventDefault();
              console.log(e.target);
              handler();
            }}
            ref={form}
          >
            <div className="form-group">
              <input
                className="form-field"
                type="email"
                placeholder="Enter username"
                onChange={(e) => {
                  setUsername(e.target.value);
                  props.setUsername(e.target.value);
                }}
                required={true}
              ></input>
            </div>
            {/* <Alert severity="warning">Must be user@email.com format</Alert> */}
            <div className="form-group">
              <input
                className="form-field"
                type="password"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                required={true}
              ></input>
            </div>
            {/* <Alert severity="warning">Must be user@email.com format</Alert> */}
            <div className="form-group">
              <Button type="submit" onClick={handler}>
                Submit
              </Button>
            </div>
            <div className="form-group">
              {props.showAuthLogin ? (
                <Button
                  type="button"
                  onClick={() => {
                    setSearchParams("");
                    setTimeout(() => handler(true), 1000);
                  }}
                >
                  Auth Login
                </Button>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
